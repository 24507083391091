import { Lottie } from "@crello/react-lottie";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import SettingsIcon from "@material-ui/icons/Settings";
import WarningIcon from "@material-ui/icons/Warning";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import Image from "material-ui-image";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import loadAnimation from "../../assets/redLoading.json";
import { MoneyboxLinks } from "../../components/dashboard/MoneyboxLinks";
import ReceiptPDF from "../../components/dashboard/ReceiptPDF/ReceiptPDF";
import { fetchLinks } from "../../store/action_creators/links.actions";
import { AppActions } from "../../store/config/ActionTypes";
import { RootState } from "../../store/config/types";

const mapStateToProps = (state: RootState) => ({
  moneybox: state.moneybox,
  links: state.links,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchMoneyboxLinks: (moneyboxId: string) => dispatch(fetchLinks({ moneyboxId })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Dashboard({ moneybox, links, fetchMoneyboxLinks, history }: PropsType) {
  const startOfYear: Date = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0);

  useEffect(() => {
    if (!links.loadingLinks && !links.links && !links.linksErrorMessage && moneybox.selectedMoneybox) {
      fetchMoneyboxLinks(moneybox.selectedMoneybox.id);
    }
  }, [
    links.loadingLinks,
    links.links,
    links.linksErrorMessage,
    moneybox.selectedMoneybox,
    fetchMoneyboxLinks,
  ]);

  return (
    <div className="dashboard">
      {moneybox.selectedMoneybox ? (
        <>
          {moneybox?.selectedMoneybox?.status === 99 && (
            <div className="disabled-warning">
              <WarningIcon color="primary" />
              Esta alcancía está inactiva. No podrás realizar cambios ni modificar sus links.
            </div>
          )}
          <div className="dashboard-heading">
            <div className="moneybox-info">
              <Image
                imageStyle={{ position: "inherit", height: 80, width: "auto" }}
                style={{ display: "flex", paddingTop: 0 }}
                src={moneybox.selectedMoneybox.imageUrl}
                alt="alcancia"
              />
              <p className="name">{moneybox.selectedMoneybox.fantasyName}</p>
              <div className="links-row">
                <p className="blue-link icon-blue-link" onClick={() => history.push("/moneybox")}>
                  <SettingsIcon className="icon" fontSize="inherit" /> Modificar información de la alcancía
                </p>
                {moneybox.totals && (
                  <p className="blue-link icon-blue-link">
                    <PDFDownloadLink
                      document={
                        <Document>
                          <ReceiptPDF
                            selectedMoneybox={moneybox.selectedMoneybox}
                            totals={moneybox.totals}
                            fromDate={startOfYear}
                            toDate={new Date()}
                          />
                        </Document>
                      }
                      fileName={moneybox.selectedMoneybox.name}
                    >
                      <DownloadIcon className="icon" fontSize="inherit" /> Descargar recibo
                    </PDFDownloadLink>
                  </p>
                )}
              </div>
            </div>
            <div className="moneybox-total">
              <p className="total-speech">Totales donados hasta el momento por la alcancía</p>
              <div className="total-amounts">
                <span className="currency">$</span>
                <span className="total">{moneybox.selectedMoneybox.raisedAmount}</span>
                <span className="currency">US$</span>
                <span className="total">{moneybox.selectedMoneybox.raisedAmountDollars}</span>
              </div>
            </div>
          </div>

          <MoneyboxLinks existingLinks={links.links} />
        </>
      ) : moneybox.loadingMoneyboxes ? (
        <div className="loading">
          <Lottie height="200px" width="200px" config={{ animationData: loadAnimation, loop: true }} />
        </div>
      ) : (
        moneybox.moneyboxes && moneybox.moneyboxes.length <= 0 && <Redirect to="/newMoneybox" />
      )}
    </div>
  );
}

export default connector(Dashboard);
