import { Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import Cookies from "universal-cookie";
import { CustomSnackbar } from "../../components/common/CustomSnackbar";
import { FormTextField } from "../../components/forms/FormTextField";
import schemas from "../../data/schemas";
import { errorHelper } from "../../helpers/errorHelper";
import { login } from "../../store/action_creators/auth.actions";
import { AppActions } from "../../store/config/ActionTypes";
import { RootState, User } from "../../store/config/types";

interface Values {
  email: string;
  password: string;
}

const NON_VERIFIED_EMAIL_ERROR_CODE = 470;

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  login: (user: User) => dispatch(login(user)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Login({ auth, login, history }: PropsType) {
  const [loginIn, setLoginIn] = useState(false);
  const cookies = new Cookies();
  const hasToken = cookies.get("token") !== undefined;

  const goBack = () => {
    history.push("/landing");
  };

  const forgotPassword = () => {
    history.push("/reset");
  };

  const submit = (values: Values) => {
    const user: User = {
      email: values.email,
      password: values.password,
    };

    setLoginIn(true);
    login(user);
  };

  const closeSnack = () => {
    setLoginIn(false);
  };

  useEffect(() => {
    if (auth.logInErrorMessage && loginIn) {
      const plainMessage = auth.logInErrorMessage.split(":")[1].substring(1);
      const mailNotVerifiedMessage = errorHelper.getErrorString(NON_VERIFIED_EMAIL_ERROR_CODE);

      if (plainMessage === mailNotVerifiedMessage) {
        history.push("/resend");
      }
    }
  });

  return !hasToken ? (
    <div className="login">
      <div className="title">
        <div className="left">
          <h2>Ingresá a tu alcancía Teletón</h2>
          <p>Completá tus datos a continuación.</p>
        </div>
        <span className="blue-link" onClick={goBack}>
          Volver
        </span>
      </div>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={schemas.LoginSchema}
        onSubmit={submit}
      >
        <Form className="form login-form">
          <Field name="email" component={FormTextField} type="text" placeholder="Email" />
          <Field name="password" component={FormTextField} type="password" placeholder="Contraseña" />
          <div className="actions-row">
            <p className="blue-link" onClick={forgotPassword}>
              ¿Olvidaste tu contraseña?
            </p>
            <Button color="secondary" variant="contained" type="submit">
              Ingresar
            </Button>
          </div>
        </Form>
      </Formik>
      <CustomSnackbar
        open={loginIn && auth.logInErrorMessage !== null}
        message="Usuario y/o contraseña incorrectos"
        handleClose={closeSnack}
        type={1}
      />
    </div>
  ) : (
    <Redirect to="/moneyboxes" />
  );
}

export default connector(withRouter(Login));
