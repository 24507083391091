import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import logo from "../../../assets/icon.png";
import { Moneybox, MoneyboxTotalsResponse } from "../../../store/config/types";

const color: string = "#DEDEDE";
const width: number = 300;
const separation: string = "24px";

const styles = StyleSheet.create({
  page: { display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#FFFFFF" },
  logo: { marginTop: 40, height: 50, width: "auto" },
  image: { height: "100%", width: "100%" },
  separator: { width: width, margin: `${separation} 0`, borderTop: `1 solid ${color}` },
  texts: { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" },
  text: { fontSize: 18, color: "#444444" },
  boldText: { fontSize: 20, fontWeight: "bold", margin: "0 4px" },
  row: { display: "flex", flexDirection: "row", alignItems: "center", margin: "4px 0" },
});

interface ReceiptPDFProps {
  selectedMoneybox: Moneybox;
  totals: MoneyboxTotalsResponse;
  fromDate: Date;
  toDate: Date;
}

function ReceiptPDF({ selectedMoneybox, totals, fromDate, toDate }: ReceiptPDFProps) {
  return (
    <Page size="A4" style={styles.page}>
      <View>
        <Image style={styles.logo} source={logo} />
      </View>
      <View style={styles.separator} />
      <View style={styles.texts}>
        <View style={styles.row}>
          <Text style={styles.text}>Teletón ha recibido</Text>
          <Text style={styles.boldText}>{totals.totalTransactions}</Text>
          <Text style={styles.text}>donaciones</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>por un total de</Text>
          <Text style={styles.boldText}>{`$${totals.raisedAmount}`}</Text>
          <Text style={styles.text}>y</Text>
          <Text style={styles.boldText}>{`USD${totals.raisedAmountDollars}`}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>a través de la alcancía</Text>
          <Text style={styles.boldText}>{selectedMoneybox.fantasyName}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>entre el</Text>
          <Text style={styles.boldText}>{fromDate.toLocaleDateString()}</Text>
          <Text style={styles.text}>y el</Text>
          <Text style={styles.boldText}>{toDate.toLocaleDateString()}</Text>
        </View>
      </View>
      <View>
        <Image style={styles.logo} source={selectedMoneybox.imageUrl} />
      </View>
    </Page>
  );
}

export default ReceiptPDF;
