import { Currency } from "../store/config/enums";
import { Link } from "../store/config/types";

//TODO
//const anonMoneybox: string = "75ef72d5-16aa-4782-ac08-85a0fbf14098"; //test
const anonMoneybox = process.env.REACT_APP_GEN_MONEYBOX_ID ? process.env.REACT_APP_GEN_MONEYBOX_ID : "";

const anonymousMoneyboxesIDs: string[] = [
  process.env.REACT_APP_TELETON_FOUNDATION_MONEYBOX_ID || "",
  process.env.REACT_APP_TELETON_POSTER_MONEYBOX_ID || "",
];

const donatePesosOptions: number[] = [100, 200, 500, 1000, 2000, 5000];
const donateDollarsOptions: number[] = [100, 200, 500];

const rawPesosOptions: number[] = [10, 20, 50, 100, 200, 500, 1000, 2000, 3000, 4000, 5000, 10000];
const rawDollarsOptions: number[] = [10, 20, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];

const pesosOptions: Link[] = rawPesosOptions.map((opt) => {
  const link: Link = {
    code: "",
    currency: Currency.PESO,
    value: opt,
  };
  return link;
});

const dollarsOptions: Link[] = rawDollarsOptions.map((opt) => {
  const link: Link = {
    code: "",
    currency: Currency.DOLAR,
    value: opt,
  };
  return link;
});

export const options = {
  anonMoneybox,
  anonymousMoneyboxesIDs,
  donatePesosOptions,
  donateDollarsOptions,
  pesosOptions,
  dollarsOptions,
};
